const slider = require('./modules/slider');
const mobile = require('./modules/mobile')();
const tabs = require('./modules/tabs');
const popups = require('./modules/popups');
const inputHandler = require('./modules/inputHandler')();
const form = require('./modules/form');
const rating = require('./modules/rating');
const citySelect = require('./modules/city-select')();

const sliderWorks = slider();
sliderWorks.init();

mobile.init();
inputHandler.init();

if ($('.popup').length) {
	const popupsInit = popups();
	popupsInit.init();
}

if ($('[data-tabs]').length) {
	const tabsInit = tabs();
	tabsInit.init();
}

if ($('.js-form-submit').length) {
	form();
}

if ($('.js-rating').length) {
	rating();
}


