module.exports = function () {

	function init() {
		_setUpListeners();
	}

	function _setUpListeners() {
		// on index
		$('.main-slider').owlCarousel({
			loop: true,
			nav: true,
			margin: 0,
			responsive: {
				0: {
					items: 1
				}
			},
			dots: true,
			dotsClass: 'slider__dots',
			dotClass: 'slider__dot',
			navContainerClass: 'slider__nav',
			navClass: ['slider__prev', 'slider__next'],
			navText: ['', ''],
			mouseDrag: true
		});

		// clients
		$('.clients-carousel').owlCarousel({
			loop: false,
			nav: true,
			margin: 10,
			responsive: {
				0: {
					items: 3
				},
				768: {
					items: 3
				},
				1024: {
					items: 6
				}
			},
			dots: false,
			navContainerClass: 'slider__nav',
			navClass: ['slider__prev', 'slider__next'],
			navText: ['', ''],
			mouseDrag: true
		});

		// offer
		$('.slider-offer-img').owlCarousel({
			loop: false,
			nav: false,
			margin: 0,
			thumbs: true,
			thumbsPrerendered: true,
			smartSpeed: 0,
			responsive: {
				0: {
					items: 1
				}
			},
			dots: false,
			mouseDrag: false
		});
	}

	return {
		init: init
	}
};