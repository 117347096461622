module.exports = function () {
	const dateInput = $('.js-input-date');
	const phoneInput = $('.js-input-phone');
	const radio = $('.js-radio');
	const checkbox = $('.js-checkbox');


	function init() {
		dateInput ? dateInput.datepicker({timepicker: true}): null;
		phoneInput ? phoneInput.mask('+ 7 (999) 999-99-99'): null;

		setUpListeners();
	}

	function setUpListeners() {
		radio.on('click', function (e) {
			e.preventDefault();
			$(this).closest('.js-radio-box').find('.js-radio').removeClass('is-active');
			$(this).addClass('is-active');
		})

		checkbox.on('click', function () {
			$(this).toggleClass('is-active');
		})
	}

	return {
		init: init
	}
};