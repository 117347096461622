module.exports = function () {
	$('.js-rating').on('click', _setRating);

	function _setRating(e) {
		/*
		 * Записываем оценку в data-rating
		 */
		$(this).closest('.js-rating').data('rating', $(e.target).index() + 1);

		$(e.target).addClass('icon-rate-star_orange').prevAll().addClass('icon-rate-star_orange');
		$(e.target).nextAll().removeClass('icon-rate-star_orange');
	}
};