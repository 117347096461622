module.exports = function () {
	function init() {
		_setUpListeners();
	}

	function _setUpListeners() {
		$('[data-tab]').on('click', _toggle);
	}

	function _toggle(e) {
		if (e.target.tagName === 'A') {
			e.preventDefault();
		}

		const tabBlock = $(this).closest('[data-tabs]');
		const index = $(this).index();

		$(this).addClass('tab-is-active').siblings().removeClass('tab-is-active');
		tabBlock.find('[data-tab-content]').eq(index).show().siblings().hide();
	}

	return {
		init: init
	}
};