module.exports = function () {
	const triggers = $('.js-popup-trigger');
	const addressSelect = $('.js-address-select');

	function init() {
		triggers.each(function (i, item) {
			const $item = $(item);

			$item.magnificPopup({
				type: 'inline',
				closeBtnInside: true,
				closeMarkup: '<div class="popup__close"><span title="%title%" class="popup__close-btn mfp-close"></span></div>'
			});
		});

		$('.js-popup-video').magnificPopup({
			type: 'iframe',
			iframe: {
				markup: '<div class="mfp-iframe-scaler">' +
				'<div class="popup popup_video-frame">' +
				'<div class="mfp-close"></div>' +
				'<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>' +
				'</div>' +
				'</div>',
			}
		});

		addressSelect.magnificPopup({
			type: 'inline',
			closeBtnInside: true,
			closeMarkup: '<div class="popup__close"><span title="%title%" class="popup__close-btn mfp-close">закрыть</span></div>'
		})
	}

	return {
		init: init
	}
};