module.exports = function () {

	const MOBILE_WIDTH = 768;
	const HEADER_NAV = $('.header__nav');
	const HEADER_TOP = $('.header__top');
	let isMobile = false;

	const sliders = [
		{
			slider: '.product-list',
			sliderItems: 1
		},
		{
			slider: '.info-block__video-list',
			sliderItems: 1
		},
	];

	const initModule = () => {
		_checkMobile();
		_setUpListeners();
	};

	const _init = () => {
		_sliderInit(sliders);
		_navInit();
		_productMetaInit();
	};

	const _setUpListeners = () => {
		$(window).on('resize', function () {
			_checkMobile();
		});

		$('[data-nav-open], .header__nav-close').on('click', _openMenu);
	};

	const _checkMobile = () => {
		$(window).width() <= MOBILE_WIDTH ? isMobile = true: isMobile = false;

		_init(sliders);
	};

	const _openMenu = (e) => {
		e.preventDefault();
		HEADER_NAV.toggle();
	};

	const _navInit = () => {
		if (isMobile) {
			HEADER_NAV.addClass('header__nav_mobile');
			if ($('.header__nav-close').length) return false;
			HEADER_NAV.append('<span class="header__nav-close">x</span>')
		} else {
			HEADER_NAV.removeClass('header__nav_mobile');
			$('.header__nav-close').length ? $('.header__nav-close').remove() : null
		}
	};

	const _productMetaInit = () => {
		if ($('.offer').length < 1) return false;

		if (isMobile) {
			$('.offer__product-info').after($('.offer-tabs-block'));
		} else {
			$('.offer__view').after($('.offer-tabs-block'));
		}
	};

	/* INIT SLIDER */
	const _sliderInit = (props) => {

		if (isMobile) {

			props.forEach((item) => {
				const slider = $(item.slider);
				const sliderItems = item.sliderItems;

				slider.addClass('owl-carousel');
				slider.owlCarousel({
					loop: true,
					nav: true,
					margin: 0,
					responsive: {
						0: {
							items: sliderItems
						}
					},
					dots: true,
					dotsClass: 'slider__dots',
					dotClass: 'slider__dot',
					navContainerClass: 'slider__nav',
					navClass: ['slider__prev', 'slider__next'],
					navText: ['', ''],
					mouseDrag: true
				});

			});

		} else {
			props.forEach((item) => {

				const slider = $(item.slider);

				if (slider.hasClass('owl-carousel')) {
					slider.removeClass('owl-carousel');
					slider.trigger('destroy.owl.carousel');
				} else {
					return false;
				}

			});

		}
	};

	return {
		init: initModule
	}

};
