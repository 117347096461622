module.exports = function () {
	const form = $('.js-form-submit');

	form.on('submit', _submitForm);

	/*
	 * Пример отправки формы
	 *
	 */

	function _submitForm(e) {
		e.preventDefault();

		const form = $(this);
		const popupThx = form.data('popup-thx') ? form.data('popup-thx') : null;

		const
			name = form.find('input[name="name"]').val(),
			phone = form.find('input[name="phone"]').val(),
			email = form.find('input[name="email"]').val();

		const data = {
			name: name,
			phone: phone,
			email: email,
		};

		const isValid = _validation(form);

		if (isValid) {
			/*
			 * Заглушка для просмотра всплывающего окна
			 * при успешной отправке формы
			 */

			/* Попап после успешной отправки */
			if (popupThx) {
				$.magnificPopup.open({
					items: {
						src: `#${popupThx}`
					},
					type: 'inline',
					closeBtnInside: true,
					closeMarkup: '<div class="popup__close"><span title="%title%" class="popup__close-btn mfp-close"></span></div>'
				});
			}

			/*
			$.ajax({
				type: "POST",
				url: "./phpmailer/mail.php",
				data: data
			}).done(function () {
				_clearForm();
			}).fail(function (error) {
				alert('Произошла ошибка! Попробуйте еще раз.');
				throw new Error(error);
			});
			*/
		}
	}

	function _validation(form) {
		const inputs = form.find('.is-required');
		let isValid = true;

		inputs.each((i, item) => {
			if (!(item.value.length > 2)) {
				isValid = false;
				$(item).addClass('is-error');

				$(item).on('focus', function () {
					$(this).removeClass('is-error');
				});
			}
		});

		return isValid;
	}

	function _clearForm(form) {
		form.find('input').val('');
		form.find('textarea').val('');
	}
};
